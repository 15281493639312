.topsection {
    margin-bottom: 140px;
}

.homepage-banner-img {
    background-image: url(https://simplicity-fintech-dev.s3.ap-south-1.amazonaws.com/public/assets/homepage/primaryBg.jpg), url(https://simplicity-fintech-dev.s3.ap-south-1.amazonaws.com/public/assets/homepage/secondaryBg.jpg);
    background-size: 20%;
    background-repeat: no-repeat;
    background-position: left bottom, right bottom;
}

.banner-description-container {
    max-width: 75%;
    margin-left: auto;
    margin-right: auto;
}

.banner-bottom {
    border-bottom: 1px solid var(--primary);
    padding-bottom: 70px;
}

.sub-description-text {
    font-size: var(--font-size-sm);
    line-height: 1.5;
    color: var(--tertiary);
    font-weight: var(--font-weight-normal);
    margin-right: 20%;
    margin-left: 20%;
}

.customized-focus-text {
    font-size: var(--font-size-xl);
    color: var(--primary);
    font-weight: var(--font-weight-bolder);
    line-height: 30px;
    padding-bottom: 15px;
}

.logo-img {
    margin: 0 10px;
    width: 100%;
    max-width: 95px;
    height: 100%;
    max-height: 170px;
}

.date-container {
    font-style: normal;
    font-weight: normal;
    font-size: var(--font-size-xxs);
    line-height: 22px;
    color: var(--tertiary);
}

.tags {
    max-width: 930px;
    margin: auto;
    display: flex;
}

.tags a {
    margin: 0 10px;
}

@media only screen and (max-width: 1699px) {
    .tags {
        max-width: 800px;
    }
}

@media only screen and (max-width: 1440px) {
    .tags {
        max-width: 600px;
    }
}


@media only screen and (max-width: 992px) {
    .banner-description-container {
        max-width: 100%;
    }

    .banner-bottom {
        border-bottom: none;
        padding-bottom: 0;
        background-image: none;
    }

    .sub-description-text {
        font-size: var(--font-size-xs);
        color: var(--tertiary);
        font-weight: var(--font-weight-normal);
        line-height: 24px;
        margin-right: 0%;
        margin-left: 0%;
    }

    .customized-focus-text {
        font-size: var(--font-size-lg);
    }

}

@media only screen and (max-width: 767px) {
    .tags {
        margin: 0 20px;
        display: flex;
        flex-flow: wrap;
        text-align: center;
        justify-content: center;
    }

    .tags a {
        margin: 10px;
    }

}

/*Carousal*/
.card-content {
    font-style: normal;
    font-size: var(--font-size-sm);
    line-height: 28px;
    color: var(--secondary);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.card-text-padding {
    padding: 30px;
    text-align: start;
}

.carousal-card-padding {
    padding: 30px;
}

.carousel-gap {
    margin-right: 10px;
    margin-left: 10px;
}

.carousel-footer-container {
    padding: 23px 40px;
}

.carousal-footer-text {
    font-style: normal;
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-sm);
    line-height: 1.2;
    letter-spacing: var(--line-spacing-0);
    text-align: left;
    color: var(--white);
}

.image-carousel-container {
    text-align: center;
    padding: 0 30px;
}

img.img {
    height: 187px;
}

.card-container {
    padding: 35px 0;
    margin-right: 25px;
    height: 100%;
}

.card-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.owl-prev {
    color: var(--primary);
    font-size: var(--font-size-lg);
    margin: 0 15px;
    background: var(--white);
    border: 1px solid var(--disable);
    width: 60px;
    height: 60px;
    border-radius: 50px;
    line-height: 50px;
    box-shadow: 0 1px 5px 3px rgba(0, 0, 0, .2);
    opacity: .3;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.owl-prev:hover {
    color: var(--primary);
    opacity: 1;
}

.knowledge-bank-container {
    margin-top: 47px;
    padding-top: 47px;
    padding-bottom: 110px;
    background-color: var(--gray);
}

.knowledge-bank-text {
    font-weight: var(--font-weight-normal);
    font-size: var(--font-size-lg);
    line-height: 35px;
    text-align: center;
    color: #4a4a4a;
    padding: 35px 20px 45px 20px;
    font-style: normal;
    letter-spacing: .3px;
}

.knowledge-bank-sub-container {
    padding: 16px;
    background: var(--white);
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.08);
    height: 100%;
}

.knowledge-bank-header-box {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 51px;
}

.knowledge-bank-sub-container-header {
    font-style: normal;
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-lg);
    line-height: 18px;
    letter-spacing: 0.3px;
    color: var(--primary);
}

.img-blog {
    width: 100%;
    height: 100%;
    max-width: 161px;
    max-height: 112px;
}

.blog-text {
    font-style: normal;
    font-weight: 500 !important;
    font-size: var(--font-size-xxs);
    line-height: 22px;
    color: var(--primary) !important;

}

.blog-text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.cardtitle {
    font-style: normal;
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-md);
    line-height: 30px;
    color: var(--primary);
    margin-top: 21px;

}

@media only screen and (max-width: 992px) {
    .carousel-container {
        overflow: hidden;
    }

    .card-text-padding {
        padding: 10px;
        text-align: start;
    }

    .card_text {
        max-width: 100% !important;
        max-height: 100% !important;
        margin: 0 !important;
    }

    .knowledge-bank-sub-container {
        padding: 40px 16px;
    }

    .knowledge-bank-container {
        margin-top: 0;
        padding-top: 70px;
        padding-bottom: 70px;
        background-color: var(--gray);
    }

    .knowledge-bank-header-box {
        display: flex;
        flex-flow: row;
        justify-content: center;
        align-items: center;
        margin-bottom: 51px;
    }



    .knowledge-bank-text {
        font-weight: 400;
        font-size: 16px;
        line-height: 28px;
        padding-top: 48px;
        padding-bottom: 48px;

    }

    .img-blog {
        width: 100%;
        height: 100%;
        max-width: 115px;
        /*max-height: 80px;*/
    }

    .card-container {
        padding: 16px 0;
        height: 95%;
    }

    .cardtitle {
        margin-top: 0;
    }

    button:focus {
        box-shadow: none;
    }

}
/*end*/


ngb-carousel.carousel.slide {
    width: 100% !important;
}

.service .owl-carousel .owl-dot,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-nav .owl-prev {
    width: 60px !important;
    height: 60px !important;
    border-radius: 132px !important;
}

.owl-nav {
    position: absolute !important;
    top: -98px !important;
    right: 28px !important;
}

.service-offer .owl-nav {
    position: absolute;
    top: -125px;
    right: 0;
}

.card-shadow {
    box-shadow: 0 2px 10px rgba(0, 0, 0, .15);
}


.btn-outline-dark:hover {
    color: var(--white) !important;
    background-color: var(--primary);
    border-color: var(--primary);
}

.btn-outline-dark {
    border-color: var(--primary);
    color: var(--primary);
    font-style: normal;
    font-weight: var(--font-weight-normal);
    font-size: var(--font-size-xs);
    line-height: 18px;
    padding: 8px 14px;
    box-shadow: none !important;
}

.next {
    background: #FDFDFD;
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.2);
    border-radius: 500px;
}

.blog {
    background: var(--gray);
    padding-top: 47px;
    margin-top: 47px;
}

.cardsection .card {
    background: var(--white);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
    border-radius: 6px;
    padding: 40px 0;
}

.ca_text {
    font-style: normal;
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-sm);
    line-height: 30px;
    letter-spacing: 0.2px;
    text-align: left;
    color: var(--white);
}

.card .card-body {
    min-height: 240px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.card .card-body .btn-outline-dark {
    margin-top: auto !important;
}



