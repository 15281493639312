.contact-main {
    padding: 0 0 110px;
}

.connectCity {
    background: var(--gray);
    width: 100%;
    height: 100%;
}

.icon_list {
    padding: 17px 0;
}

.icon_list {
    display: flex;
    align-items: inherit;
}

.icon_list i {
    font-size: 36px;
    margin-right: 20px;
}

.custom-select {
    background-image: url('../img/ContactUs/downArrow.png');
    background-repeat: no-repeat;
    background-position: calc(100% - 10px) center !important;
}

#send-msg-btn .btn:hover {
    color: var(--white) !important;
    --bs-btn-hover-color: var(--white) !important;
}

section.coffffn {
    margin-bottom: 97px;
}

.contact-main {
    padding: 187px 0 110px !important;
}

@media only screen and (min-width: 1390px) {
    .contact-main {
        padding: 187px 0 110px !important;
    }
}


@media only screen and (max-width: 991px) {
    .contact-main {
        padding: 30px 0 0;
    }

    .icon_list i {
        font-size: 20px;
        margin-right: 15px;
    }

    section.coffffn {
        margin-bottom: 35px;
    }

    .submit-btn-shadow {
        box-shadow: 0 -1px 32.8px 0 rgba(0, 0, 0, 0.1);
        padding-right: 12px;
        padding-left: 12px;
    }
}


@media only screen and (max-width: 767px) {
    section.coffffn {
        margin-bottom: 0;
    }
}
