.bottom-menu-container {
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.1);
    background-color: #FFFFFF;
    height: 60px;
    position: fixed;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}

.bottom-menu-border-top {
    border-top: 0.5px solid rgba(207, 207, 207, 1);
}

.bottom-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    height: 100%;
}

.bottom-menu-name {
    font-size: 10px;
    color: #000000;
    line-height: 1;
    padding: 0;
}

.bottom-menu-active-border-top {
    border-top: 2px solid rgba(0, 0, 0, 1);
}


.more-container {
    border-top: 1px solid #8D8D8D;
    /*position: absolute;*/
    /*bottom: 59px;*/
    /*background-color: white;*/
    width: 100%;
    display: flex;
    flex-direction: column;

    /*margin-bottom: 60px;*/
}

.more-container-option {
    border: 1px solid #B5B5B5;
}

.more-option-menu-text {
    font-size: 14px;
    font-weight: 400;
    color: #000000;

}

.more-option-line {
    margin: 42px 0 32px 0;
    border: 4px solid #9F9F9F;
    border-radius: 10px;
    width: 44px;
}
