@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');


.container-x{
    padding-left: 110px;
    padding-right: 110px;
}

.main-container{
    height: calc(100dvh - 135px);
}
.section-description-padding{
    padding-top: 30px;
    padding-bottom: 60px;
}
.section-bg{
    background: var(--gray);
}
.icon-title {
    font-style: normal;
    font-weight: var(--font-weight-bolder);
    font-size: var(--font-size-xl);
    line-height: 1;
    letter-spacing: var(--line-spacing-0);
    color: var(--tertiary);
}

.icon-text{
    font-style: normal;
    font-weight: var(--font-weight-300);
    font-size: var(--font-size-md);
    line-height: 26px;
    letter-spacing: var(--line-spacing-0);
    color: var(--primary);
}

.link-gap {
    padding-left: 3px;
}

.img-block {
    display: flex;
    align-items: center;
    justify-content: center;

}
.bannerImg {
    object-fit: contain;
    max-height: 70vh;
}



/*about us*/
/*.introduction-title {*/
/*    font-style: normal;*/
/*    font-weight: var(--font-weight-semibold);*/
/*    font-size: var(--font-size-xl);*/
/*    letter-spacing: var(--line-spacing-0);*/
/*    color: var(--primary);*/
/*}*/


.about-us-client-section {
    background: var(--gray);
}

.about-us-secondary-title{
    font-style: normal;
    font-weight: var(--font-weight-bolder);
    line-height: 56px;
    letter-spacing: var(--line-spacing-0);
    font-size: var(--font-size-xxl);
    color: var(--tertiary);
    margin-top: 59px;
    margin-bottom: 96px;
}

.meet-us-department{
    font-style: normal;
    font-weight: var(--font-weight-300);
    font-size: var(--font-size-xl);
    line-height: 56px;
    letter-spacing: var(--line-spacing-0);
    color: var(--active);
    border: none;
    padding: 0 43px;
    background-color: var(--white);
}
.meet-us-department:active{
    font-weight: var(--font-weight-bolder);
}

.about-us-name {
    font-style: normal;
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-lg);
    letter-spacing: var(--line-spacing-0);
    color: var(--active);
    text-align: center;
    padding-top: 37px;
}

.about-us-role{
    font-size: var(--font-size-sm);
    font-weight: var(--font-weight-300);
    line-height: 18px;
    letter-spacing: var(--line-spacing-0);
    text-align: center;
}
.font-family-itallic{
    font-family: Poppins, sans-serif;
    font-weight: var(--font-weight-300) !important;
    /*font-weight: 300 !important;*/
    font-style: italic;
}

#myTab .nav-link {
    font-style: normal;
    font-weight: var(--font-weight-300);
    font-size: var(--font-size-xl);
    line-height: 56px;
    letter-spacing: var(--line-spacing-0);
    color: var(--active);
    border: none;
    padding: 0 43px !important;
}


.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: var(--active);
    font-weight: var(--font-weight-bold) !important;
}

.nav-tabs {
    border-bottom: transparent;
    margin-bottom: 14px;
}



/*product Css start*/
.products-button{
    width: 253px;
    height: 54px;
    font-size: var(--font-size-sm);
    font-weight: var(--font-weight-bolder);
    line-height: 21px;
    color: var(--primary);
    text-align: center;
    border: 1px solid var(--primary);
    display: flex;
    align-items: center;
    justify-content: center;
}

.products-button:hover {
    color: var(--primary) !important;
}

.products-button:active{
    color: var(--primary) !important;
}


/*product Css End*/

/*knowledge bank start*/
.knowledge-bank-title-pb{
    padding-bottom: 80px;
}

.search-box{
    width: 100%;
    font-size: 1rem;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    padding: 6px 12px !important;
}
.card.whitepaper-blogcard {
    border: none;
    margin-bottom: 62px ;
}

.date {
    max-height: 37px;
    font-size: var(--font-size-sm);
}

.knowledge-bank-name{
    font-style: normal;
    line-height: 36px;
    font-weight: var(--font-weight-semibold);
    font-size: var(--font-size-lg);
    color: var(--tertiary);
}

.table-subBlock{
    margin: 0;
    padding: 0;
    font-size: 18px;
    letter-spacing: .2px;
    font-weight: var(--font-weight-300);
    width: 75% !important;
}

i.fa.fa-search {
    position: relative;
    float: right;
    top: -49%;
    right: 4%;
}
/*knowledge bank End*/





@media only screen and (max-width: 1200px) {
    .container-x{
        padding-left: 50px;
        padding-right: 50px;
    }
    .main-container{
        height: 100%;
    }

}


@media only screen and (max-width: 992px) {
    .container-x{
        padding-left: 24px;
        padding-right: 24px;
    }

    .about-us-secondary-title{
        font-size: var(--font-size-xl);
        line-height: 39px;
        margin-bottom: 24px;
        margin-top: 1px;
    }

/*    knowledge bank*/
    .knowledge-bank-title-pb{
        padding-bottom: 50px;
    }
}
