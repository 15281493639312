.para-select-profile {
    text-align: center;
    color: #737b7d;
    font-style: normal;
    font-weight: var(--font-weight-normal);
    letter-spacing: .3px;
    font-size: var(--font-size-md);
    margin-left: 14%;
    margin-right: 14%;
}



.head-block-select-para {
    width: 100%;
    max-width: 660px;
    margin: 0 auto;
    align-content: center;
}

.img-container {
    width: 80%;
    height: 100% !important;
    object-fit: contain;
}

.bg-business {
    background-image: url(https://simplicity-fintech-dev.s3.ap-south-1.amazonaws.com/public/assets/selectYourProfile/businessPrimaryBg.jpg);
    background-size: 100%;
    min-height: calc(100vh - 135px);
    background-position-y: 28px;
    background-repeat: no-repeat;
}

.bg-for-her {
    background-image: url(https://simplicity-fintech-dev.s3.ap-south-1.amazonaws.com/public/assets/selectYourProfile/forHerPrimaryBg.jpg);
    background-size: 100%;
    background-position-y: -53px;
    min-height: calc(100vh - 135px);
    background-repeat: no-repeat;
}

.bg-for-her-banner {
    background-image: url(https://simplicity-fintech-dev.s3.ap-south-1.amazonaws.com/public/assets/selectYourProfile/forHerSecondaryBg.jpg);
    background-size: 100%;
    min-height: calc(100vh - 135px);
    background-repeat: no-repeat;
}

.bg-global {
    background-image: url(https://simplicity-fintech-dev.s3.ap-south-1.amazonaws.com/public/assets/selectYourProfile/globalPrimaryBg.jpg);
    background-size: 100%;
    background-position-y: 125px;
    min-height: calc(100vh - 135px);
    background-repeat: no-repeat;
}

.bg-global-banner {
    background-image: url(https://simplicity-fintech-dev.s3.ap-south-1.amazonaws.com/public/assets/selectYourProfile/globalSecondaryBg.jpg);
    background-size: 100%;
    background-repeat: no-repeat;
    min-height: calc(100vh - 135px);
}

.bg-new-gen {
    background-image: url(https://simplicity-fintech-dev.s3.ap-south-1.amazonaws.com/public/assets/selectYourProfile/newGenPrimaryBg.png);
    background-size: 100%;
    background-position-y: 125px;
    min-height: calc(100vh - 135px);
    background-repeat: no-repeat;
}

.bg-new-gen-banner {
    background-image: url(https://simplicity-fintech-dev.s3.ap-south-1.amazonaws.com/public/assets/selectYourProfile/newGenSecondaryBg.jpg);
    background-size: 100%;
    min-height: calc(100vh - 135px);
    background-repeat: no-repeat;
}


.bg-professional {
    background-image: url(https://simplicity-fintech-dev.s3.ap-south-1.amazonaws.com/public/assets/selectYourProfile/professionalPrimaryBg.png);
    background-size: 100%;
    background-position-y: -20px;
    min-height: calc(100vh - 135px);
    background-repeat: no-repeat;
}

.bg-professional-banner {
    background-image: url(https://simplicity-fintech-dev.s3.ap-south-1.amazonaws.com/public/assets/selectYourProfile/professionalSecondaryBg.jpg);
    background-size: 80%;
    min-height: calc(100vh - 135px);
    background-repeat: no-repeat;
}


.bg-retire {
    background-image: url(https://simplicity-fintech-dev.s3.ap-south-1.amazonaws.com/public/assets/selectYourProfile/retireePrimaryBg.jpg);
    background-size: 100%;
    background-position-y: 30px;
    min-height: calc(100vh - 135px);
    background-repeat: no-repeat;
}

.bg-retire-banner {
    background-image: url(https://simplicity-fintech-dev.s3.ap-south-1.amazonaws.com/public/assets/selectYourProfile/retireeSecondaryBg.jpg);
    background-size: 100%;
    min-height: calc(100vh - 135px);
    background-repeat: no-repeat;
}


.bg-salaried {
    background-image: url(https://simplicity-fintech-dev.s3.ap-south-1.amazonaws.com/public/assets/selectYourProfile/salariedPrimaryBg.jpg);
    background-size: 100%;
    background-position-y: 125px;
    min-height: calc(100vh - 135px);
    background-repeat: no-repeat;
}

.bg-salaried-banner {
    background-image: url(https://simplicity-fintech-dev.s3.ap-south-1.amazonaws.com/public/assets/selectYourProfile/salariedSecondaryBg.jpg);
    background-size: 100%;
    min-height: calc(100vh - 135px);
    background-repeat: no-repeat;
}

.icon-orange , .icon-orange:hover{
    background-color: var(--orange);
}
.icon-violet , .icon-violet:hover{
    background-color: var(--violet);
}
.icon-blue , .icon-blue:hover{
    background-color: var(--blue);
}
.icon-green , .icon-green:hover{
    background-color: var(--green);
}
.icon-light-blue , .icon-light-blue:hover{
    background-color: var(--light-blue);
}
.icon-dark-red , .icon-dark-red:hover{
    background-color: var(--dark-red);
}
.icon-burnt-orange , .icon-burnt-orange:hover{
    background-color: var(--burnt-orange);
}

@media only screen and (max-width: 992px) {

    .bg-business {
        min-height: 100%;
        background-image: none;
    }

    .bg-for-her{
        min-height: 100%;
        background-image: none;
    }

    .bg-global{
        min-height: 100%;
        background-image: none;
    }
    .bg-new-gen{
        min-height: 100%;
        background-image: none;
    }
    .bg-professional{
        min-height: 100%;
        background-image: none;
    }
    .bg-salaried{
        min-height: 100%;
        background-image: none;
    }
    .bg-retire{
        min-height: 100%;
        background-image: none;
    }
}
