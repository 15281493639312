:root { /* Colors: */

    --primary: #000000;
    --secondary: #4A4A4A;
    --tertiary: #373F41;
    --white: #FFFFFF;
    --disable: #0000008C;
    --active: #282828;

    /*--success: #029C48;*/
    /*--success-light: #E1FFE5;*/
    --danger: red;
    --orange: #ffc000;
    --violet: rgb(112, 48, 160);
    --blue: rgb(68, 114, 196);
    --green: rgb(112, 173, 71);
    --light-blue: rgb(91, 155, 213);
    --dark-red: rgb(192, 0, 0);
    --burnt-orange: rgb(237, 125, 49);
    --gray: #F7F7F7;
    --gray-dark: #666666;
    /*--gray: #c5c5c5;*/
    /*--black: #282828;*/
    /*--link: #0073ff;*/

    --primary-text: #000000;
    --secondary-text: #4A4A4A;
    --tertiary-text: #373F41;
    --white-text: #FFFFFF;

    /*--success-text: #029C48;*/
    /*--black-text: #282828;*/

    /* Font/text values */
    --font-family-poppins: Poppins;
    --font-style-normal: normal;
    --font-weight-bolder: 700;
    --font-weight-bold: 600;
    --font-weight-semibold: 500;
    --font-weight-normal: 400;
    --font-weight-300: 300;


    --font-size-xxs: 12px;
    --font-size-xs: 14px;
    --font-size-sm: 16px;
    --font-size-md: 18px;
    --font-size-lg: 24px;
    --font-size-xl: 30px;
    --font-size-xxl: 40px;
    --font-size-xxxl: 50px;
    --line-height: 1.2;
    --line-spacing-0: 0.2;
    --character-spacing-0: 0px;

}
