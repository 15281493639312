.header-container {
    padding: 9px 50px;
}

.logo-icon {
    margin-right: 34px;
    width: 70px;
}

.header-site-name{
    font-size: var(--font-size-xs) ;
    font-style: italic;
    font-weight: var(--font-weight-bold);
    line-height: 17px;
    text-align: left;
}

.nav-item {
    padding: 8px 30px 8px 0;
}

.nav-link {
    padding: 0 !important;
    font-style: normal;
    font-size: var(--font-size-sm);
    font-weight: var(--font-weight-normal);
    line-height: 12px;
    color: var(--active) !important;
}

.nav-link-active > a {
    color: var(--active)!important;
    font-weight: var(--font-weight-bold) !important;
}

.button-css {
    font-size:var(--font-size-sm);
    padding: 6px 12px;
    height: 37.6px;

}

.primary-dropdown-color {
    color: var(--primary) !important;
    font-weight: var(--font-weight-300) !important;

}

.dropdown-item:active {
    color: #212529;
    text-decoration: none;
    background-color: #E8ECEF;
}

.dropdown-item {
    color: #0000008C;
}

.secondary-header a:hover {
    color: inherit !important;
}

.secondary-header .dropdown-item:hover {
    color: #0000008C !important;
}

.dropdown-item-active {
    color: #212529;
    font-weight:var(--font-weight-bold);

}

/*end*/
.secondary-header-container {
    gap: 35px;
    border-top: 1px solid #b9b9b9;
    overflow-y: auto;
    scrollbar-width: none;
}

#dropdownMenuButton:active {
    color: var(--bs-btn-active-color);
    background-color: var(--bs-btn-active-bg);
    border-color: var(--bs-btn-active-border-color);
}

.active-dropdown-link {
    color: #0000008C !important;
    text-decoration: none;
    background-color: var(--gray);
}

.primary-header-container .nav-link {
    padding: 0 !important;
    font-style: normal;
    font-size: var(--font-size-sm);
    font-weight: var(--font-weight-normal);
    line-height: 12px;
    color:var(--active) !important;
}

.primary-header-container .nav-link-active {
    color: var(--active) !important;
    font-weight: var(--font-weight-bold) !important;
}


.secondary-header-container .nav-link {
    padding: 0 !important;
    font-style: normal;
    font-size: var(--font-size-sm);
    font-weight: var(--font-weight-normal);
    line-height: 12px;
    color:var(--active) !important;
    flex-shrink: 0;
}

.secondary-header-container .nav-link:hover {
    background-color: var(--white) !important;
}

.secondary-header-container .nav-link:active {
    color: var(--primary);
    font-weight: var(--font-weight-bold) !important;
    background-color: var(--white) !important;

}

.secondary-header .dropdown-item:active {
    color: #0000008C;
    background-color: inherit;
}

.sign-up-button-mob {
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-xs);
    line-height: 21px;

}

@media only screen and (min-width: 992px) {
    .header-container {
        padding: 9px 16px;
    }

    .logo-icon {
        margin-right: 10px;
    }
    .header-site-name{
        font-size: var(--font-size-xs) ;
        font-style: italic;
        font-weight: var(--font-weight-normal);
        line-height: 17px;
        text-align: left;

    }
}

.parent-container {
    display: flex;
    height: 100vh;
    flex-grow: 1;
}

.child-20 {
    width: 10%;
}

.child-80 {
    width: 90%;
    display: flex;
    flex-direction: column;
}

.child-80-top {
    padding-top: 10px;
    flex: 1;
}

.child-80-bottom {
    padding-top: 10px;
    flex: 2;
    overflow: hidden;
}

.child-80-bottom.scrollable {
    overflow-y: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.child-80-bottom.scrollable::-webkit-scrollbar {
    display: none;
}


@media only screen and (min-width: 1200px) {
    .child-20 {
        width: 7%;
    }

    .child-80 {
        width: 93%;
        display: flex;
        flex-direction: column;
    }

}
